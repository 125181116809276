import section_1 from '../components/section_1/section_1.vue'
import section_2 from '../components/section_2/section_2.vue'
import section_3 from '../components/section_3/section_3.vue'
import section_4 from '../components/section_4/section_4.vue'
import section_5 from '../components/section_5/section_5.vue'
import section_6 from '../components/section_6/section_6.vue'
import section_7 from '../components/section_7/section_7.vue'
import section_8 from '../components/section_8/section_8.vue'
import admin from '../components/admin/admin.vue'

import Preloader from '../components/preloader/preloader.vue'
import {mapGetters, mapMutations} from 'vuex'
import { disablePageScroll, enablePageScroll, clearQueueScrollLocks, addScrollableSelector  } from 'scroll-lock';
import WheelIndicator from 'wheel-indicator'
export default {
    name: 'App',
    components: {
        section_1,
        section_2,
        section_3,
        section_4,
        section_5,
        section_6,
        section_7,
        section_8,
        Preloader,
        admin
    },
    data() {
        return {
            marker: true,
            delta: null,
            direction: '',
            interval: 150,
            counter1: 0,
            counter2: 0,
            counter3: 0,
            counter4: 0,
            counter: new Date().getTime(),
            prev: null,
            next: null,
            topCountLocal: 0,
            loading: true,
            show: true,
            k: 0,


            multi: 0,
            pause: false,
            multiPrev: 0,

            isAdmin: false,
            initialPoint: null,
            finalPoint: null,
        }
    },
    methods: {
        ...mapMutations(['setTopCount', 'setVis', 'detectDevice']),
        // cl(e){
        //   console.log(e);
        // },
        // swipe(e) {
        //     if (!this.loading) {
        //         if (this.touchDevice) {
        //         }
        //     }
        // },
        // scroll(e){
        //     if (!this.loading) {
        //         if (window.pageYOffset == 0) {
        //             if (new Date().getTime() - this.counter > 700 ) {
        //                 e = e || window.event;
        //                 this.delta = e.deltaY || e.detail || e.wheelDelta;
        //                 this.counter1 = this.counter1 + 1;
        //                 if (this.delta > 0) {
        //                     this.direction = 'up';
        //                 }
        //                 else if (this.delta <= 0) {
        //                     this.direction = 'down';
        //                 }
        //                 if (this.marker && this.delta != '-0') {
        //                     this.wheelStart(e)
        //                 }
        //             }
        //
        //         }
        //     }
        //
        // },
        // wheelStart(e){
        //     if (this.direction == 'up') {
        //         document.body.style['overflow-y'] = 'hidden';
        //         disablePageScroll(document.body);
        //         if (this.topCount < 3) {
        //             this.setTopCount({count: this.topCount + 1})
        //             e.preventDefault ? e.preventDefault() : (e.returnValue = false);
        //         } else {
        //                 clearQueueScrollLocks(document.body);
        //                 enablePageScroll(document.body);
        //                 document.body.style['overflow-y'] = 'auto';
        //         }
        //     } else if (this.direction == 'down'){
        //         if (this.topCount > 0) {
        //             document.body.style['overflow-y'] = 'hidden';
        //             disablePageScroll(document.body);
        //             this.setTopCount({count: this.topCount - 1});
        //             e.preventDefault ? e.preventDefault() : (e.returnValue = false);
        //         }
        //     }
        //     this.marker = false;
        //     this.wheelAct();
        //     this.counter3 = new Date();
        //     this.counter = new Date().getTime();
        // },
        // wheelAct(){
        //     this.counter2 = this.counter1;
        //     setTimeout(() => {
        //         if (this.counter2 == this.counter1) {
        //             this.wheelEnd();
        //         } else {
        //             this.wheelAct();
        //         }
        //     }, this.interval);
        // },
        // wheelEnd(){
        //     this.counter4 = new Date();
        //     this.marker = true;
        //     this.counter1 = 0;
        //     this.counter2 = false;
        //     this.counter3 = false;
        //     this.counter4 = false;
        //     console.log('end');
        // },
        visible (target, k) {
            let targetPosition = {
                    top: window.pageYOffset + target.getBoundingClientRect().top,
                    left: window.pageXOffset + target.getBoundingClientRect().left,
                    right: window.pageXOffset + target.getBoundingClientRect().right,
                    bottom: window.pageYOffset + target.getBoundingClientRect().bottom
                },
                windowPosition = {
                    top: window.pageYOffset,
                    left: window.pageXOffset,
                    right: window.pageXOffset + document.documentElement.clientWidth,
                    bottom: window.pageYOffset + document.documentElement.clientHeight
                };

            if (targetPosition.bottom > windowPosition.top && // Если позиция нижней части элемента больше позиции верхней чайти окна, то элемент виден сверху
                targetPosition.top < windowPosition.bottom && // Если позиция верхней части элемента меньше позиции нижней чайти окна, то элемент виден снизу
                targetPosition.right > windowPosition.left && // Если позиция правой стороны элемента больше позиции левой части окна, то элемент виден слева
                targetPosition.left < windowPosition.right) { // Если позиция левой стороны элемента меньше позиции правой чайти окна, то элемент виден справа
                this.setVis(k);
            }
        },
    },
    computed: {
        ...mapGetters(['topCount', 'touchDevice', 'from'])
    },
    watch: {
        topCount(next, prev) {
            this.next = next;
            this.prev = prev;
            this.topCountLocal = this.topCount
        },
    },
    mounted() {
        const app = document.getElementById('app');
        this.detectDevice();

        document.body.addEventListener('touchstart', (event) => {
            if (this.topCount < 3) {
                event.preventDefault();
                event.stopPropagation();
            }
            this.initialPoint=event.changedTouches[0];
        });
        document.body.addEventListener('touchend', (event) => {
            this.finalPoint=event.changedTouches[0];
            let xAbs = Math.abs(this.initialPoint.pageX - this.finalPoint.pageX);
            let yAbs = Math.abs(this.initialPoint.pageY - this.finalPoint.pageY);
            if (xAbs > 20 || yAbs > 20) {
                if (xAbs > yAbs) {
                } else {
                    if (!this.loading) {
                        if (window.pageYOffset < 20) {
                            if (this.finalPoint.pageY < this.initialPoint.pageY) {
                                if (this.topCount < 3) {
                                    document.body.style['overflow-y'] = 'hidden';
                                    disablePageScroll(document.body);
                                    this.setTopCount({count: this.topCount + 1})
                                    if (this.topCount == 3) {
                                        clearQueueScrollLocks(document.body);
                                        enablePageScroll(document.body);
                                        document.body.style['overflow-y'] = 'auto';
                                    } else {
                                        event.preventDefault();
                                        event.stopPropagation();
                                    }

                                } else {
                                    clearQueueScrollLocks(document.body);
                                    enablePageScroll(document.body);
                                    document.body.style['overflow-y'] = 'auto';
                                }
                            } else {
                                if (this.topCount > 0) {
                                    document.body.style['overflow-y'] = 'hidden';
                                    disablePageScroll(document.body);
                                    this.setTopCount({count: this.topCount - 1})
                                    event.preventDefault();
                                    event.stopPropagation();
                                }
                            }
                        }
                    }

                }
            }
        });

        let indicator = new WheelIndicator({
            elem: app,
            callback: (e) => {
                if (window.pageYOffset == 0) {

                    this.delta = e.deltaY || e.detail || e.wheelDelta;
                    if (this.delta > 0) {
                        this.direction = 'up';
                    } else if (this.delta <= 0) {
                        this.direction = 'down';
                    }
                    if (this.direction == 'up') {
                        document.body.style['overflow-y'] = 'hidden';
                        disablePageScroll(document.body);
                        if (this.topCount < 3) {
                            this.setTopCount({count: this.topCount + 1})
                            e.preventDefault ? e.preventDefault() : (e.returnValue = false);
                        } else {
                            clearQueueScrollLocks(document.body);
                            enablePageScroll(document.body);
                            document.body.style['overflow-y'] = 'auto';
                        }
                    } else if (this.direction == 'down') {
                        if (this.topCount > 0) {
                            document.body.style['overflow-y'] = 'hidden';
                            disablePageScroll(document.body);
                            this.setTopCount({count: this.topCount - 1});
                            e.preventDefault ? e.preventDefault() : (e.returnValue = false);
                        }
                    }
                }
            },
            preventMouse: false,
        })

        document.body.style['overflow-y'] = 'hidden';
        addScrollableSelector('.my-scrollable-selector');
        disablePageScroll(app);
        disablePageScroll(document.body);
        disablePageScroll(document.getElementById('non_scroll'));

        let ev = document.querySelector('#event');
        let wine = document.querySelector('#wine');
        let box = document.querySelector('#box');
        let cont = document.querySelector('#contacts');
        window.addEventListener('scroll', (e) => {
            this.visible(ev, 'event');
            this.visible(wine, 'wine');
            this.visible(box, 'box');
            this.visible(cont, 'cont');
        });
        this.visible(ev, 'event');
        this.visible(wine, 'wine');
        this.visible(box, 'box');
        this.visible(cont, 'cont');
        window.addEventListener('beforeunload', () => {
            window.scrollTo(0,0)
        })

        let lthis = this;
        function preloadImage(url, w)
        {

            if (w === 'img') {
                let img = new Image();
                img.src = url;
                img.onload = function() {
                    lthis.k = lthis.k+1;
                    if (lthis.k > 42) lthis.loading = false;
                }
            } else {
                let video = document.getElementById("myVideo");
                video.load();
                video.addEventListener("canplay", function() {
                    lthis.k = lthis.k+1;
                    if (lthis.k > 42) lthis.loading = false;
                });

            }
        }
        preloadImage(require('../../public/svg/bottles/bottle_1.png'), 'img')
        preloadImage(require('../../public/svg/bottles/bottle_2.png'), 'img')
        preloadImage(require('../../public/svg/bottles/bottle_3.png'), 'img')
        preloadImage(require('../../public/svg/bottles/blanc-left.svg'), 'img')
        preloadImage(require('../../public/svg/bottles/blanc-right.svg'), 'img')
        preloadImage(require('../../public/svg/bottles/pino-left.svg'), 'img')
        preloadImage(require('../../public/svg/bottles/pino-right.svg'), 'img')
        preloadImage(require('../../public/svg/bottles/rose-left.svg'), 'img')
        preloadImage(require('../../public/svg/bottles/rose-right.svg'), 'img')
        preloadImage(require('../../public/svg/bottles/bottleDescription1.svg'), 'img')
        preloadImage(require('../../public/svg/bottles/descriptionBorder.svg'), 'img')
        preloadImage(require('../../public/svg/bottles/technology.svg'), 'img')

        preloadImage(require('../../public/svg/buy-modal/bottles-modal.svg'), 'img')
        preloadImage(require('../../public/svg/buy-modal/krestik.svg'), 'img')
        preloadImage(require('../../public/svg/buy-modal/mngp-box-title.svg'), 'img')
        preloadImage(require('../../public/svg/buy-modal/pino 1.png'), 'img')
        preloadImage(require('../../public/svg/buy-modal/rose 1.png'), 'img')
        preloadImage(require('../../public/svg/buy-modal/rose 3.png'), 'img')

        preloadImage(require('../../public/svg/footerbar/contacts.svg'), 'img')
        preloadImage(require('../../public/svg/footerbar/event.svg'), 'img')
        preloadImage(require('../../public/svg/footerbar/wine.svg'), 'img')
        preloadImage(require('../../public/svg/footerbar/winery.svg'), 'img')

        preloadImage(require('../../public/svg/mngp-box/box.png'), 'img')
        preloadImage(require('../../public/svg/mngp-box/box-title.svg'), 'img')
        preloadImage(require('../../public/svg/mngp-box/buy-button.svg'), 'img')
        preloadImage(require('../../public/svg/mngp-box/remainder.svg'), 'img')

        preloadImage(require('../../public/svg/navbar/navitem1.svg'), 'img')
        preloadImage(require('../../public/svg/navbar/navitem2.svg'), 'img')
        preloadImage(require('../../public/svg/navbar/navitem3.svg'), 'img')
        preloadImage(require('../../public/svg/navbar/navitem4.svg'), 'img')

        preloadImage(require('../../public/svg/other/goodbye.svg'), 'img')
        preloadImage(require('../../public/svg/other/menWithSunglasses.svg'), 'img')
        preloadImage(require('../../public/svg/other/spica.svg'), 'img')
        preloadImage(require('../../public/svg/other/turn.svg'), 'img')

        preloadImage(require('../../public/svg/social/instagram.svg'), 'img')
        preloadImage(require('../../public/svg/social/locate.svg'), 'img')
        preloadImage(require('../../public/svg/social/mail.svg'), 'img')
        preloadImage(require('../../public/svg/social/map.svg'), 'img')
        preloadImage(require('../../public/svg/social/phone.svg'), 'img')

        preloadImage(require('../../public/svg/titles/emotions_on_bottles.svg'), 'img')
        preloadImage(require('../../public/svg/underline_event.svg'), 'img')
        preloadImage(require('../../public/svg/underline_wine.svg'), 'img')
        preloadImage(null, 'video')
    },
    created () {
        document.title = "MNGP";
        if (location.pathname == '/admin') {
            this.isAdmin = true;
        }
    }
}