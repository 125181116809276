import {mapMutations} from 'vuex'

export default {
    name: "section_1",
    data() {
        return{

        }
    },
    methods: {
        ...mapMutations(['setTopCount']),
        winery() {
            this.setTopCount({count: 1});
        },
        event() {
            this.setTopCount({count: 3});
            document.body.style['overflow-y'] = 'auto';
        },
        wine() {
            document.body.style['overflow-y'] = 'auto';
            this.setTopCount({count: 3, from: true});
            setTimeout(() => {
                document.querySelector('#wine').scrollIntoView({ behavior: 'smooth',alignToTop: true});
            }, 1)
        },
        contacts() {
            document.body.style['overflow-y'] = 'auto';
            this.setTopCount({count: 3, from: true});
            setTimeout(() => {
                document.querySelector('#contacts').scrollIntoView({ behavior: 'smooth',alignToTop: true});
            }, 1)
        }
    },
}
