import {
    mapGetters
} from 'vuex'
export default {
    name: "section_3",
    computed: mapGetters(['topCount']),
    watch: {
        topCount(){
            if (this.topCount === 2 ) {
                document.getElementById('myVideo').play()
            }
        }
    }
}

