var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isAdmin)?_c('admin'):_c('div',{staticStyle:{"overflow-x":"hidden","overflow-y":"hidden","width":"100vw"},attrs:{"id":"app"}},[_c('transition',{attrs:{"name":"bounce"}},[(_vm.loading)?_c('Preloader'):_vm._e()],1),_c('div',{staticStyle:{"overflow":"hidden","display":"flex","align-items":"flex-end"},style:({
            transition: _vm.from? '' :'.7s ease',
            height: _vm.topCountLocal == 0? '100vh' : '0vh',
            })},[_c('section_1')],1),_c('div',{staticStyle:{"overflow":"hidden","display":"flex"},style:({
            transition: _vm.from? '' :'.7s ease',
            height: _vm.topCountLocal == 1? '100vh' : '0vh',
                    'align-items': _vm.next == 2 && _vm.prev == 1? 'flex-end' : _vm.next == 1 && _vm.prev == 2? 'flex-end' : ''
        })},[_c('section_2')],1),_c('div',{staticStyle:{"overflow":"hidden","display":"flex"},style:({
                    transition: _vm.from? '' :'.7s ease',
            height: _vm.topCountLocal == 2? '100vh' : '0vh',
        'align-items': _vm.next == 3 && _vm.prev == 2? 'flex-end' : _vm.next == 2 && _vm.prev == 3? 'flex-end' : ''})},[_c('section_3')],1),_c('div',{staticStyle:{"transition":".7s ease"},style:(_vm.topCountLocal == 3 ? {} : {height: '0vh',
                                 transition: _vm.from? '' :'.7s ease',
             }),attrs:{"id":"specForHeight"}},[_c('section_4',{staticClass:"container"})],1),_c('section_5',{staticClass:"section_5_margin"}),_c('section_6'),_c('section_7')],1)}
var staticRenderFns = []

export { render, staticRenderFns }