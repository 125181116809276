import {mapGetters} from "vuex";

export default {
    data() {
        return {

        }
    },
    name: "section_4",
    methods: {

    },
    mounted() {

    },
    computed: {
        ...mapGetters(['topCount'])
    }
}
