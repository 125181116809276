import {mapGetters} from 'vuex'
export default {
    name: "section_2",
    data() {
        return{
            stars: [],
            star0: false,
            star1: false,
            star2: false,
            star3: false,
            star4: false,
            star5: false,
            star6: false,
            star7: false,
            star8: false,
            star9: false,
            star10: false,
            star11: false,
            star12: false,
            star13: false,

            win: false,
        }
    },
    methods: {
        try() {
            if (window.innerWidth > 1200
            && this.star0
            && this.star1
            && this.star2
            && this.star3
            && this.star4
            && this.star5
            && this.star6
            && this.star7
            && this.star8
            && this.star9
            && this.star10
            && this.star11
            && this.star12) {
                this.win = true;
            } else if (window.innerWidth >= 768
                && this.star0
                && this.star1
                && this.star2
                && this.star3
                && this.star4
                && this.star5
                && this.star6
                && this.star13
                && this.star8
                && this.star9
                && this.star10
                && this.star11
                && this.star12) {
                this.win = true;
            }
        },
        clickStar(number) {
            if (window.innerWidth >= 768) {
                switch(number) {
                    case 0 : {
                        if (!this.star0) {
                            this.star0 = true;
                            this.try();
                        }
                        break;
                    }
                    case 1 : {
                        if (!this.star1) {
                            this.star1 = true;
                            this.try();
                        }
                        break;
                    }
                    case 2 : {
                        if (!this.star2) {
                            this.star2 = true;
                            this.try();
                        }
                        break;
                    }
                    case 3 : {
                        if (!this.star3) {
                            this.star3 = true;
                            this.try();
                        }
                        break;
                    }
                    case 4 : {
                        if (!this.star4) {
                            this.star4 = true;
                            this.try();
                        }
                        break;
                    }
                    case 5 : {
                        if (!this.star5) {
                            this.star5 = true;
                            this.try();
                        }
                        break;
                    }
                    case 6 : {
                        if (!this.star6) {
                            this.star6 = true;
                            this.try();
                        }
                        break;
                    }
                    case 7 : {
                        if (!this.star7) {
                            this.star7 = true;
                            this.try();
                        }
                        break;
                    }
                    case 8 : {
                        if (!this.star8) {
                            this.star8 = true;
                            this.try();
                        }
                        break;
                    }
                    case 9 : {
                        if (!this.star9) {
                            this.star9 = true;
                            this.try();
                        }
                        break;
                    }
                    case 10 : {
                        if (!this.star10) {
                            this.star10 = true;
                            this.try();
                        }
                        break;
                    }
                    case 11 : {
                        if (!this.star11) {
                            this.star11 = true;
                            this.try();
                        }
                        break;
                    }
                    case 12 : {
                        if (!this.star12) {
                            this.star12 = true;
                            this.try();
                        }
                        break;
                    }
                    case 13 : {
                        if (!this.star13) {
                            this.star13 = true;
                            this.try();
                        }
                        break;
                    }

                    default: break;
                }
            }

        }
    },
    mounted() {
        for (let i = 0; i < 16; i++) {
            this.stars[i] = false;
        }
    },
    computed: {
        ...mapGetters(['topCount'])
    },
    watch: {
        win() {
            if (!this.win) {
                this.star0 = false;
                this.star1 = false;
                this.star2 = false;
                this.star3 = false;
                this.star4 = false;
                this.star5 = false;
                this.star6 = false;
                this.star13 = false;
                this.star7 = false;
                this.star8 = false;
                this.star9 = false;
                this.star10 = false;
                this.star11 = false;
                this.star12 = false;
            }
        }
    }
}
