import {mapGetters} from "vuex";

export default {
    name: "section_6",
    data() {
        return {
            description_mngp_box: 'Наши вина можно приобрести только комплектом, который включает в себя три различных сорта вина: каждому настроению — свой вкус. Количество боксов ограниченно, поэтому не стоит откладывать покупку на потом.',
            show: false,
            show2: false,
            count: 1,
            show3: false,
            count_bottles: 0,
            name: '',
            email: '',
            phone: '',
            errName: null,
            errEmail: null,
            errPhone: null,
            mask: ['+7 (', /\d/, /\d/, /\d/, ') ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
        }
    },
    methods: {
        zakazat() {
            document.body.style['overflow-y'] = "hidden";
            const y = document.querySelector(".mngp-container").getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            this.show = true;
            setTimeout(() => {
                this.show2 = true;
            }, 350);
        },
        close() {
            this.show2 = false;
            setTimeout(() => {
                this.show = false;
                document.body.style['overflow-y'] = "auto";
                this.show3 = false;
            }, 350);
        },
        minus() {
            this.count = this.count - 1;
            if (this.count <= 1) {
                this.count = 1;
            }
        },
        plus() {
            this.count = this.count + 1;
            if (this.count >= 5) {
                this.count = 5;
            }
        },
        zakazat_submit() {
            this.checkValid('email');
            this.checkValid('phone');
            this.checkValid('name');
            if (!this.errEmail && !this.errName && !this.errPhone) {
                fetch('https://vinebogdan.herokuapp.com/api/new', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'Application/json'
                    },
                    body: JSON.stringify({
                        name: this.name,
                        email: this.email,
                        phone: this.phone,
                        kol: this.count
                    })
                })
                    .then( res => res.json())
                    .then( () => {
                        setTimeout(() => {
                            this.show3 = true;
                        }, 350);
                        this.show2 = false;
                    })
            }


        },
        checkValid(obj) {
            switch (obj) {
                case 'email' : {
                    if (this.email) {
                        let k = this.email.indexOf('@');
                        if (k + 1 > 2) {
                            let p = this.email.slice(k + 1);
                            let t = p.indexOf('.');
                            if (t > 1) {
                                if (p.slice(t).length > 2) {
                                    this.errEmail = false;
                                    break;
                                }
                            }
                        }
                    }
                    this.errEmail = true;
                    break;
                }
                case 'phone' : {
                    this.phone?.length > 17 ? this.errPhone = false : this.errPhone = true;
                    break;
                }
                case 'name' : {
                    this.name? this.errName = false : this.errName = true;
                }
                default:
                    break;
            }
        }

    },
    computed: {
        ...mapGetters(['visBox'])
    },
    mounted() {
        document.addEventListener('keydown', (e) => {
            if (e.code == 'Escape') {
                this.close()
            }
        });
        fetch('https://vinebogdan.herokuapp.com/api/view_kol')
            .then( res => res.json())
            .then( res => this.count_bottles = res.kol)
    },
    watch: {
        name() {
            if (this.name) {
                this.name = this.name.replace(/[^А-Яа-яёЁA-Za-z -]/, '')
                if (this.name[0] == ' ') {
                    this.name = this.name.slice(1);
                } else {
                    this.name = this.name.slice(0, 1).toUpperCase() + this.name.slice(1);
                }
                let p = this.name;
                let kLast = 0;
                while (true) {
                    let k = p.indexOf(' ', kLast);
                    if (k + 1) {
                        if (p[k + 1] == ' ') {
                            p = p.slice(0, k + 1) + p.slice(k + 2);
                        } else {
                            p = p.slice(0, k + 1) + p.slice(k + 1, k + 2).toUpperCase() + p.slice(k + 2);
                        }
                    } else break;
                    kLast = k + 1;
                }
                this.name = p;
            }
        },
        email() {
            if (this.email) {
                this.email = this.email.replace(/[^a-zA-Z0-9@._-]/, '')
            }
        },
    }
}
