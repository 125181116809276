

export default {
    data() {
        return {
            login: '',
            password: '',
            token: '',
            isLog: false,
            count: '',
            offers: []
        }
    },
    methods: {
        log() {
            fetch('https://vinebogdan.herokuapp.com/token', {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer '+ btoa(this.login+':'+this.password)

                }
            })
                .then(res => res.json())
                .then(res => {
                    this.token = res.token;
                    this.isLog = true;
                })
                .then(() => {
                    fetch('https://vinebogdan.herokuapp.com/api/all', {
                        method: 'GET',
                        headers: {
                            'Authorization': 'Bearer '+ this.token
                        }
                    })
                        .then( res => res.json())
                        .then(res => this.offers = res)
                    fetch('https://vinebogdan.herokuapp.com/api/view_kol', {
                        method: 'GET',
                        headers: {
                            'Authorization': 'Bearer '+ this.token
                        }
                    })
                        .then( res => res.json())
                        .then(res => this.count = res.kol)
                })
        },
        update() {
            if (this.count) {
                fetch('https://vinebogdan.herokuapp.com/api/update_kol', {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer '+ this.token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({kol: this.count})
                })
            }

        }
    }
}