import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    topCount: 0,
    touchDevice: null,
    visEvent: false,
    visWine: false,
    visBox: false,
    visCont: false,
    from: false,
  },
  mutations: {
    setTopCount(state, count) {
      if (count.from) {
        state.from = true
      } else {
        state.from = false
      }
      state.topCount = count.count
    },
    detectDevice(state) {
      state.touchDevice = ('ontouchstart' in window || 'onmsgesturechange' in window);
    },
    setVis(state, k) {
      switch(k) {
        case 'event': {
          state.visEvent = true;
          break;
        }
        case 'wine': {
          state.visWine = true;
          break;
        }
        case 'box': {
          state.visBox = true;
          break;
        }
        case 'cont': {
          state.visCont = true;
          break;
        }
        default: break
      }
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    topCount(state) {
      return state.topCount
    },
    visEvent(state) {
      return state.visEvent
    },
    visWine(state) {
      return state.visWine
    },
    visBox(state) {
      return state.visBox
    },
    visCont(state) {
      return state.visCont
    },
    touchDevice(state) {
      return state.touchDevice
    },
    from(state) {
      return state.from
    }
  }
})
