import {mapGetters} from "vuex";

export default {
    name: "section_5",
    data: function () {
        return {
            titleWine1: 'Rose',
            dateWine1: 'Розе, 2020',
            technology1: 'Вино ферментировалось в стальных емкостях при низкой температуре, выдержка на винном дрожжевом осадке 4 месяца',

            titleWine2: 'Pinot Noir',
            dateWine2: 'Пино Нуар, 2020',
            technology2: 'Вино ферментировалось в стальных емкостях при контролируемой температуре брожения, выдержка в французских барриках 8 месяцев',

            titleWine3: 'Sauvignon Blanc',
            dateWine3: 'Совиньон Блан, 2020',
            technology3: 'Вино ферментировалось в стальных емкостях при низкой температуре, выдержка на винном дрожжевом осадке 4 месяца',

            isActive0: false,
            isActive1: false,
            isActive2: false,
            isActive3: false,

            popup_wine_1: false,
            popup_wine_2: false,
            popup_wine_3: false,
            block: false,

            isZ1: false,
            isZ2: false,
            isZ3: false,

            isDisactiveCLose: true,
        }
    },
    methods: {
        wine_1(e) {
            if (this.isActive0 && this.isActive1 && this.isZ1) {
                this.hide()
            } else {
                this.isActive0 = true;
                this.isActive1 = true;
                this.isZ1 = true;
                document.body.style['overflow-y'] = "hidden";
                const y = document.querySelector(".bottle-1").getBoundingClientRect().top + window.pageYOffset - 70;
                window.scrollTo({top: y + (window.innerHeight > 700 ? 0 : 40), behavior: 'smooth'});
                this.popup_wine_1 = true;
                this.block = true;
            }
        },
        wine_2(e) {
            if (this.isActive0 && this.isActive2 && this.isZ2) {
                this.hide()
            } else {
                this.isActive0 = true;
                this.isActive2 = true;
                this.isZ2 = true;
                document.body.style['overflow-y'] = "hidden";
                const y = document.querySelector(".bottle-2").getBoundingClientRect().top + window.pageYOffset - 70;
                window.scrollTo({top: y + (window.innerHeight > 700? 0: 40), behavior: 'smooth'});
                this.popup_wine_2 = true;
            }

        },
        wine_3(e) {
            if (this.isActive0 && this.isActive3 && this.isZ3) {
                this.hide()
            } else {
                this.isActive0 = true;
                this.isActive3 = true;
                this.isZ3 = true;
                document.body.style['overflow-y'] = "hidden";
                const y = document.querySelector(".bottle-3").getBoundingClientRect().top + window.pageYOffset - 70;
                window.scrollTo({top: y + (window.innerHeight > 700 ? 0 : 40), behavior: 'smooth'});
                this.popup_wine_3 = true;
                this.block = true;
            }
        },


        hide() {
            setTimeout(() => {
                this.block = false;
                this.isZ1 = false;
                this.isZ2 = false;
                this.isZ3 = false;
            }, 350);
            document.body.style['overflow-y'] = "visible";
            this.isActive0 = false;
            this.isActive1 = false;
            this.isActive2 = false;
            this.isActive3 = false;

            this.popup_wine_1 = false;
            this.popup_wine_2 = false;
            this.popup_wine_3 = false;
        }
    },
    computed: {
        ...mapGetters(['visWine'])
    },
    mounted() {
        document.addEventListener('keydown', (e) => {
            if (e.code == 'Escape' && this.isActive0) {
                this.hide()
            }
        });
    }
}

