import Vue from 'vue'
import App from './app/app.vue'
import store from './store'
import Vue2TouchEvents from 'vue2-touch-events'
import smoothscroll from 'smoothscroll-polyfill';
import VueMask from 'v-mask'

Vue.use(VueMask, {
  placeholders: {
    Я: /[а-яА-Я]/, // cyrillic letter as a placeholder
  }
})

// kick off the polyfill!
smoothscroll.polyfill();
// Vue.use(Vue2TouchEvents, {
//   disableClick: false,
//   touchClass: '',
//   tapTolerance: 10,
//   touchHoldTolerance: 400,
//   swipeTolerance: 70,
//   longTapTimeInterval: 400
// })
Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);


