import {mapGetters} from 'vuex'


export default {
    name: "section_3",
    data() {
        return {
            active: false,
            audio: new Audio('/audio/passmurny - Сердце (mp3cut.net).mp3')
        }
    },
    computed: {
        ...mapGetters(['visCont'])
    },
    methods: {
        playGuitar() {
            this.active = !this.active;
        }
    },
    watch: {
        active() {
            if (this.active) {
                this.audio.play();
            } else {
                this.audio.pause();
            }
        }
    }
}
